














































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const StatististicsStore = namespace('StatististicsStore')

import BaseTitle from '@/components/base/BaseTitle.vue'
import Chart from '@/components/Chart.vue'

@Component({
  name: 'Dashboards',
  components: { BaseTitle, Chart },
  metaInfo: {
    title: 'Статистика',
  },
})
export default class DashboardsPage extends Vue {
  @StatististicsStore.Getter
  public statististicsList!: any

  @StatististicsStore.Action
  private getStatististics!: () => Promise<any>

  public async created(): Promise<void> {
    await this.getStatististics()

    console.log(this.statististicsList)
  }
}
